<template>
  <div>
    <div
      class="
        container-fluid
        header-content
        mb-40
        pos-relative
        height-res-tab-small-50 height-res-100
      "
    >
      <div class="container-standar pos-relative h-100 h-res-tab-small-80">
        <div class="pos-absolute bottom-10 margin-auto text-center w-100"></div>
      </div>
    </div>
    <div class="cotainer-standar mt--180">
      <div class="dk-container dk-potensi">
        <span class="d-block text-center font-30 text-white font-weight-6 mb-40"
          >REPOSITORY PROVINSI PAPUA BARAT</span
        >
        <div
          class="container-standar mt-10 mb-10 text-center pad-30 card shadow"
        >
          <h4 class="font-weight-7 text-primary font-20 bor-bot-1">
            <template v-if="filter == false">
              <span
                @click="filter = true"
                class="
                  float-right
                  cursor-pointer
                  border
                  pad-10
                  bg-primary
                  border-radius-5
                "
              >
                <i class="fa fa-filter text-white"></i>
              </span>
            </template>
            <template v-else>
              <span
                @click="filter = false"
                class="
                  float-right
                  cursor-pointer
                  border
                  pad-10
                  bg-danger
                  border-radius-5
                "
              >
                <i class="fa fa-times text-white"></i>
              </span>
            </template>
          </h4>
          <div class="row mt-20" v-if="filter">
            <div class="col-lg-3">
              <v-select
                placeholder="Pilih Sektor"
                :options="optionsSektor"
                v-model="filterRep.sektor"
                label="sektor"
              ></v-select>
            </div>
            <div class="col-lg-3">
              <v-select
                placeholder="Pilih Jenis Dokumen"
                :options="optionsJenisDokumen"
                v-model="filterRep.jenis_dokumen"
                label="jenis_dokumen"
              ></v-select>
            </div>
            <div class="col-lg-3">
              <v-select
                placeholder="Pilih Format Dokumen"
                :options="optionsFormatDokumen"
                v-model="filterRep.format_dokumen"
                label="format_dokumen"
              ></v-select>
              <!-- <b-form-input
                v-model="finder"
                placeholder="Pilih Desa/Kampung"
              ></b-form-input> -->
            </div>
            <div class="col-lg-3">
              <b-button
                @click="send(filterRep)"
                class="
                  bg-primary
                  text-white
                  border-radius-5
                  pad-5
                  cursor-pointer
                  font-weight-6
                  d-inline-block
                  width-100
                "
                >Filter Repository</b-button
              >
            </div>
          </div>
        </div>
        <div class="container-standar mb-10 mt-10 pad-20 card shadow">
          <h4
            v-if="onFilter === false"
            class="text-primary font-20 mb-20 font-weight-7 bor-bot-1 pb-4"
          >
            Total Repository : 2 item
          </h4>
          <h4
            v-else
            class="text-primary font-20 mb-20 font-weight-7 bor-bot-1 pb-4"
          >
            Total Repository : 2 item
          </h4>
          <div class="text-center" v-if="loading">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
          </div>
          <div class="row" v-else>
            <div class="col-lg-4 mb-12 cursor-pointer" v-for="i in 2" :key="i">
              <router-link
                :to="{
                  name: 'Detail Repository',
                  params: { id: i },
                }"
              >
                <b-card no-body class="shadow">
                  <b-row no-gutters>
                    <b-col md="4">
                      <b-img
                        src="https://d3e22dkm536m45.cloudfront.net/cover_book/2022/04/06/Buku%20Panduan%20Puskesmas%20Kampung%20Tatanan%20Normal%20Baru%20Pandemi%20COVID-19.jpg"
                        style="width: 140px; height: 200px"
                        fluid
                        alt="Image"
                        class="responsive rounded-0"
                      ></b-img>
                    </b-col>
                    <b-col md="8">
                      <b-card-body
                        title="Buku Panduan Puskesmas Kampung Tatanan Normal Baru Pandemi COVID-19"
                      >
                        <span
                          class="text-dark d-inline-block width-100 font-14"
                        >
                          Buku Panduan Puskesmas Kampung Tatanan Normal Baru
                          Pandemi COVID-19
                        </span>
                        <span class="text-grey font-12">
                          Format Dokumen: PDF
                        </span>
                      </b-card-body>
                    </b-col>
                  </b-row>
                </b-card>
                <!-- <div class="list-kampung shadow card">
                  <div class="img-container">
                    <img
                      src="https://ozip.com.au/wp-content/uploads/2018/11/Raja-Ampat-salah-satu-destinasi-wisata-Papua.jpg"
                      alt
                    />
                  </div>
                  <div class="content p-3 pb-n4">
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kampung/Desa :
                      <span class="font-weight-7 text-primary"
                        >{{ kampung.type }} {{ kampung.name }}</span
                      >
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Distrik :
                      <span class="font-weight-7 text-primary">{{
                        kampung.districts.name
                      }}</span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kabupaten :
                      <span class="font-weight-7 text-primary">{{
                        kampung.cities.name
                      }}</span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Kepala Kampung/Desa :
                      <span class="font-weight-7 text-primary">{{
                        kampung.chairman_name
                      }}</span>
                    </span>
                    <span class="text-dark d-inline-block width-100 font-16">
                      Perkiraan jumlah keluarga :
                      <span class="font-weight-7 text-primary">{{
                        kampung.perkiraan_jumlah_keluarga
                      }}</span>
                    </span>
                  </div>
                </div> -->
              </router-link>
            </div>
          </div>

          <div
            class="
              flex
              width-100
              justify-center
              text-center
              mt-50
              pb-res-tab-small-40
            "
          >
            <b-pagination
              color="primary"
              v-model="page"
              :total-rows="total"
              :per-page="6"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
const repository = createNamespacedHelpers("repository");

export default {
  data() {
    return {
      finder: null,
      filterRep: {
        sektor: null,
        jenis_dokumen: null,
        format_dokumen: null,
      },
      optionsSektor: [],
      optionsJenisDokumen: [],
      optionsFormatDokumen: [],
      page: 1,
      filter: true,
      onFilter: false,
      imgUrl: "https://picsum.photos/400/400/?image=20",
    };
  },
  computed: {
    ...repository.mapState(["repositories", "loading", "total"]),
    ...repository.mapGetters(["repositoryList"]),
  },
  mounted() {
    this.$store.commit("SET_BG", true);
    // this.getListSektor();
    // this.getListFormatDokumen();
    // this.getListJenisDokumen();
  },
  created() {
    // this.getRepositoryList(this.page);
  },
  watch: {
    page() {
      if (this.onFilter === false) {
        this.getRepositoryList(this.page);
      } else {
        let filter = {
          sektor: this.filterRep.sektor ? this.filterRep.sektor.sektor : "",
          jenis_dokumen: this.filterRep.jenis_dokumen
            ? this.filterRep.jenis_dokumen.jenis_dokumen
            : "",
          id_format_dokument: this.filterRep.format_dokumen
            ? this.filterRep.format_dokumen.id
            : "",
        };
        this.filterRepositoryList({ form: filter, page: this.page });
      }
    },
  },
  methods: {
    ...repository.mapActions(["getRepositoryList", "filterRepositoryList"]),
    getListSektor() {
      this.$axios.get("/data-sektor").then((response) => {
        this.optionsSektor = response.data.data;
      });
    },
    getListFormatDokumen() {
      this.$axios.get("/data-format-dokumen").then((response) => {
        this.optionsFormatDokumen = response.data.data;
      });
    },
    getListJenisDokumen() {
      this.$axios.get("/data-jenis-dokumen").then((response) => {
        this.optionsJenisDokumen = response.data.data;
      });
    },
    send(form) {
      let filter = {
        sektor: form.sektor ? form.sektor.sektor : "",
        jenis_dokumen: form.jenis_dokumen
          ? form.jenis_dokumen.jenis_dokumen
          : "",
        id_format_dokument: form.format_dokumen ? form.format_dokumen.id : "",
      };
      this.page = 1;
      this.filterRepositoryList({ form: filter, page: this.page });
      this.onFilter = true;
    },
  },
};
</script>
